import React from 'react';

import { button } from './print-button.module.scss';

import prependApiUrl from '../../utils/prepend-api-url';

interface IPrintButtonProps {
    className?: string;
    slug: string;
    Icon: React.ElementType;
    title?: string;
}

const PrintButton: React.FC<IPrintButtonProps> = ({ className = '', slug, Icon, title }) => {
    return (
        <a
            target="_blank"
            rel="noreferrer noopener"
            href={prependApiUrl(slug)}
            className={`${button} ${className}`}
            title={title}
        >
            <Icon />
        </a>
    );
};

export default PrintButton;
