import React from 'react';

import { printWidget, buttonContainer } from './print-widget.module.scss';
import useTranslations from '../../hooks/use-translations';
import PrintIcon from '../../assets/images/svg/print.svg';
import PrintImagesIcon from '../../assets/images/svg/print-images.svg';

import PrintButton from '../atoms/print-button';
import Heading from '../atoms/heading';

export interface IPrintWidgetProps {
    className?: string;
    slug: string;
}

export default function PrintWidget({ className = '', slug }: IPrintWidgetProps) {
    const t = useTranslations('PrintWidget');
    return (
        <div className={`${printWidget} ${className}`}>
            <Heading numberOfStyle={8} theme="accent" isRegular>
                {t.content}
            </Heading>
            <div className={buttonContainer}>
                <PrintButton slug={`/${slug}/pdf`} Icon={PrintIcon} title={t.printTitle} />
                <PrintButton
                    slug={`/${slug}/pdf?images=1`}
                    Icon={PrintImagesIcon}
                    title={t.printImages}
                />
            </div>
        </div>
    );
}
