import React from 'react';
import { graphql } from 'gatsby';
import { IPageContext, IQueryAllResult } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    container,
    wrapper,
    articleWrapper,
    articleHeader,
    articleHeading,
    articleLead,
    ratioImage,
} from './labeled-post.module.scss';
import relations from '../config/relations';
import { ILabeledPost } from '../models/labeled-post.model';
import { INews } from '../models/news.model';
import { getNodes } from '../utils/get-nodes';
import useMenuPaginationData from '../hooks/use-menu-pagination-data';

import MainLayout from '../layouts/main-layout';
import Heading from '../components/atoms/heading';
import Markdown from '../components/hoc/markdown';
import RatioImage from '../components/atoms/ratio-image';
import RelatedNews from '../components/molecules/related-news';
import PrintWidget from '../components/molecules/print-widget';
import Pagination from '../components/molecules/pagination';

export interface ILabeledPostProps {
    pageContext: IPageContext & { showPagination: boolean };
    data: {
        covidData: ILabeledPost | null;
        ukraineData: ILabeledPost | null;
        allNews: IQueryAllResult<INews>;
    };
}

const LabeledPost: React.FC<ILabeledPostProps> = ({ data, pageContext }) => {
    const { allNews } = data;
    const { pathname, showPagination } = pageContext;
    const paginationData = useMenuPaginationData(pathname);

    const relatedNews = getNodes(allNews);

    const labeledPost = getLabeledPost(data, pageContext);

    if (!labeledPost) return null;

    return (
        <MainLayout className={container}>
            <div className={wrapper}>
                <RatioImage
                    media={labeledPost.media}
                    relation={relations.mainImage}
                    ratioClass={ratioImage}
                    preserveSpace={false}
                />
                <div className={articleWrapper}>
                    <div className={articleHeader}>
                        <Heading numberOfStyle={3} className={articleHeading}>
                            {labeledPost.title}
                        </Heading>
                        <PrintWidget slug={labeledPost.printSlug} />
                    </div>
                    <Heading className={articleLead} numberOfStyle={5} isRegular>
                        {labeledPost.lead}
                    </Heading>
                    <Markdown>{labeledPost.content}</Markdown>
                    {showPagination && <Pagination as="page" {...paginationData} />}
                </div>
                {relatedNews.length > 0 && <RelatedNews news={relatedNews} />}
            </div>
        </MainLayout>
    );
};

function getLabeledPost(
    data: ILabeledPostProps['data'],
    pageContext: IPageContext
): (ILabeledPost & Record<'printSlug', string>) | null {
    if (pageContext.type === 'ukraine' && data.ukraineData) {
        return {
            ...data.ukraineData,
            printSlug: 'ukraine',
        };
    }
    if (pageContext.type === 'covid-19' && data.covidData) {
        return {
            ...data.covidData,
            printSlug: 'covid',
        };
    }
    return null;
}

export default LabeledPost;

export const query = graphql`
    query ($isUkrainePage: Boolean = false, $isCovidPage: Boolean = false, $locale: String!) {
        covidData(locale: { eq: $locale }) @include(if: $isCovidPage) {
            title
            lead
            content
            media {
                ...mediaFields
            }
        }

        ukraineData(locale: { eq: $locale }) @include(if: $isUkrainePage) {
            title
            lead
            content
            media {
                ...mediaFields
            }
        }

        allNews(
            filter: { isCovid: { eq: $isCovidPage }, isUkraine: { eq: $isUkrainePage } }
            sort: { fields: publishedAt, order: DESC }
            limit: 3
        ) {
            edges {
                node {
                    ...newsCardFields
                }
            }
        }
    }
`;
