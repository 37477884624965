import React from 'react';

import { related, relatedHeading } from './related-news.module.scss';
import { INews } from '../../models/news.model';
import useTranslations from '../../hooks/use-translations';

import Heading from '../atoms/heading';
import NewsListing from './news-listing';

interface IRelatedNewsProps {
    className?: string;
    news: INews[];
}

const RelatedNews: React.FC<IRelatedNewsProps> = ({ className = '', news }) => {
    const t = useTranslations('RelatedNews');

    return (
        <section className={`${className} ${related}`}>
            <Heading className={relatedHeading} numberOfStyle={3}>
                {t.relatedNewsHeading}
            </Heading>
            <NewsListing news={news} />
        </section>
    );
};

export default RelatedNews;
